/*
        This font software is the property of Commercial Type.

        You may not modify the font software, use it on another website, or install it on a computer.

        License information is available at http://commercialtype.com/eula
        For more information please visit Commercial Type at http://commercialtype.com or email us at info[at]commercialtype.com

        Copyright (C) 2018 Schwartzco Inc.
        License: 1805-TITXUQ
*/

@font-face {
  font-family: "Graphik LC Web";
  src: url("./Graphik-SuperItalic-Cy-Web.eot");
  src: url("./Graphik-SuperItalic-Cy-Web.eot?#iefix")
      format("embedded-opentype"),
    url("./Graphik-SuperItalic-Cy-Web.woff2") format("woff2"),
    url("./Graphik-SuperItalic-Cy-Web.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

.Graphik-SuperItalic-Cy-Web {
  font-family: "Graphik LC Web";
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik LC Web";
  src: url("./Graphik-Super-Cy-Web.eot");
  src: url("./Graphik-Super-Cy-Web.eot?#iefix") format("embedded-opentype"),
    url("./Graphik-Super-Cy-Web.woff2") format("woff2"),
    url("./Graphik-Super-Cy-Web.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Super-Cy-Web {
  font-family: "Graphik LC Web";
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik LC Web";
  src: url("./Graphik-BlackItalic-Cy-Web.eot");
  src: url("./Graphik-BlackItalic-Cy-Web.eot?#iefix")
      format("embedded-opentype"),
    url("./Graphik-BlackItalic-Cy-Web.woff2") format("woff2"),
    url("./Graphik-BlackItalic-Cy-Web.woff") format("woff");
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

.Graphik-BlackItalic-Cy-Web {
  font-family: "Graphik LC Web";
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik LC Web";
  src: url("./Graphik-Black-Cy-Web.eot");
  src: url("./Graphik-Black-Cy-Web.eot?#iefix") format("embedded-opentype"),
    url("./Graphik-Black-Cy-Web.woff2") format("woff2"),
    url("./Graphik-Black-Cy-Web.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Black-Cy-Web {
  font-family: "Graphik LC Web";
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik LC Web";
  src: url("./Graphik-BoldItalic-Cy-Web.eot");
  src: url("./Graphik-BoldItalic-Cy-Web.eot?#iefix") format("embedded-opentype"),
    url("./Graphik-BoldItalic-Cy-Web.woff2") format("woff2"),
    url("./Graphik-BoldItalic-Cy-Web.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

.Graphik-BoldItalic-Cy-Web {
  font-family: "Graphik LC Web";
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik LC Web";
  src: url("./Graphik-Bold-Cy-Web.eot");
  src: url("./Graphik-Bold-Cy-Web.eot?#iefix") format("embedded-opentype"),
    url("./Graphik-Bold-Cy-Web.woff2") format("woff2"),
    url("./Graphik-Bold-Cy-Web.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Bold-Cy-Web {
  font-family: "Graphik LC Web";
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik LC Web";
  src: url("./Graphik-SemiboldItalic-Cy-Web.eot");
  src: url("./Graphik-SemiboldItalic-Cy-Web.eot?#iefix")
      format("embedded-opentype"),
    url("./Graphik-SemiboldItalic-Cy-Web.woff2") format("woff2"),
    url("./Graphik-SemiboldItalic-Cy-Web.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

.Graphik-SemiboldItalic-Cy-Web {
  font-family: "Graphik LC Web";
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik LC Web";
  src: url("./Graphik-Semibold-Cy-Web.eot");
  src: url("./Graphik-Semibold-Cy-Web.eot?#iefix") format("embedded-opentype"),
    url("./Graphik-Semibold-Cy-Web.woff2") format("woff2"),
    url("./Graphik-Semibold-Cy-Web.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Semibold-Cy-Web {
  font-family: "Graphik LC Web";
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik LC Web";
  src: url("./Graphik-MediumItalic-Cy-Web.eot");
  src: url("./Graphik-MediumItalic-Cy-Web.eot?#iefix")
      format("embedded-opentype"),
    url("./Graphik-MediumItalic-Cy-Web.woff2") format("woff2"),
    url("./Graphik-MediumItalic-Cy-Web.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

.Graphik-MediumItalic-Cy-Web {
  font-family: "Graphik LC Web";
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik LC Web";
  src: url("./Graphik-Medium-Cy-Web.eot");
  src: url("./Graphik-Medium-Cy-Web.eot?#iefix") format("embedded-opentype"),
    url("./Graphik-Medium-Cy-Web.woff2") format("woff2"),
    url("./Graphik-Medium-Cy-Web.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Medium-Cy-Web {
  font-family: "Graphik LC Web";
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik LC Web";
  src: url("./Graphik-RegularItalic-Cy-Web.eot");
  src: url("./Graphik-RegularItalic-Cy-Web.eot?#iefix")
      format("embedded-opentype"),
    url("./Graphik-RegularItalic-Cy-Web.woff2") format("woff2"),
    url("./Graphik-RegularItalic-Cy-Web.woff") format("woff");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

.Graphik-RegularItalic-Cy-Web {
  font-family: "Graphik LC Web";
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik LC Web";
  src: url("./Graphik-Regular-Cy-Web.eot");
  src: url("./Graphik-Regular-Cy-Web.eot?#iefix") format("embedded-opentype"),
    url("./Graphik-Regular-Cy-Web.woff2") format("woff2"),
    url("./Graphik-Regular-Cy-Web.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Regular-Cy-Web {
  font-family: "Graphik LC Web";
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik LC Web";
  src: url("./Graphik-LightItalic-Cy-Web.eot");
  src: url("./Graphik-LightItalic-Cy-Web.eot?#iefix")
      format("embedded-opentype"),
    url("./Graphik-LightItalic-Cy-Web.woff2") format("woff2"),
    url("./Graphik-LightItalic-Cy-Web.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

.Graphik-LightItalic-Cy-Web {
  font-family: "Graphik LC Web";
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik LC Web";
  src: url("./Graphik-Light-Cy-Web.eot");
  src: url("./Graphik-Light-Cy-Web.eot?#iefix") format("embedded-opentype"),
    url("./Graphik-Light-Cy-Web.woff2") format("woff2"),
    url("./Graphik-Light-Cy-Web.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Light-Cy-Web {
  font-family: "Graphik LC Web";
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik LC Web";
  src: url("./Graphik-ExtralightItalic-Cy-Web.eot");
  src: url("./Graphik-ExtralightItalic-Cy-Web.eot?#iefix")
      format("embedded-opentype"),
    url("./Graphik-ExtralightItalic-Cy-Web.woff2") format("woff2"),
    url("./Graphik-ExtralightItalic-Cy-Web.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

.Graphik-ExtralightItalic-Cy-Web {
  font-family: "Graphik LC Web";
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik LC Web";
  src: url("./Graphik-Extralight-Cy-Web.eot");
  src: url("./Graphik-Extralight-Cy-Web.eot?#iefix") format("embedded-opentype"),
    url("./Graphik-Extralight-Cy-Web.woff2") format("woff2"),
    url("./Graphik-Extralight-Cy-Web.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Extralight-Cy-Web {
  font-family: "Graphik LC Web";
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik LC Web";
  src: url("./Graphik-ThinItalic-Cy-Web.eot");
  src: url("./Graphik-ThinItalic-Cy-Web.eot?#iefix") format("embedded-opentype"),
    url("./Graphik-ThinItalic-Cy-Web.woff2") format("woff2"),
    url("./Graphik-ThinItalic-Cy-Web.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

.Graphik-ThinItalic-Cy-Web {
  font-family: "Graphik LC Web";
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: "Graphik LC Web";
  src: url("./Graphik-Thin-Cy-Web.eot");
  src: url("./Graphik-Thin-Cy-Web.eot?#iefix") format("embedded-opentype"),
    url("./Graphik-Thin-Cy-Web.woff2") format("woff2"),
    url("./Graphik-Thin-Cy-Web.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

.Graphik-Thin-Cy-Web {
  font-family: "Graphik LC Web";
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}

html {
  width: 100%;
  height: 100%;
  min-height: 600px;
  min-width: 375px;

  font-size: 100%;
  font-size: 10px;
}

body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  font-family: "Graphik LC Web", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  position: relative;
  height: 100%;
  min-height: 100%;
}

@media screen and (max-width: 560px) {
  html {
    font-size: 8px;
  }
}
